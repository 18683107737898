

































































































































import Vue from 'vue';
import * as Api from '@/api/productResourcePool';
export default Vue.extend({
  name: 'resourceForm',
  data() {
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      regionData: [],
      parentResources: [],
      versionDisabled: false,
      loading: false,
      form: {
        resName: '',
        serviceCode: undefined,
        basePath: '',
        parentSequence: undefined,
        resVersion: '',
        requestMapping: '',
        requestMethod: undefined,
        resDescription: '',
      },
    };
  },
  computed: {
    formRules() {
      const rules_1 = {
        serviceCode: [
          {
            required: true,
            message: '请选择资源来源',
            trigger: 'change',
          },
        ],
        basePath: [
          {
            required: true,
            message: '请输入资源地址',
            trigger: 'change',
          },
        ],
        resName: [
          {
            required: true,
            message: '请输入资源名称',
            trigger: 'change',
          },
        ],
        resVersion: [
          {
            required: true,
            message: '请输入资源版本',
            trigger: 'change',
          },
        ],
        requestMapping: [
          {
            required: true,
            message: '请输入资源操作地址',
          },
        ],
        requestMethod: [
          {
            required: true,
            message: '请选择资源操作类型',
          },
        ],
      };
      const rules_2 = {
        serviceCode: [
          {
            required: true,
            message: '请选择资源来源',
            trigger: 'change',
          },
        ],
        basePath: [
          {
            required: true,
            message: '请输入资源地址',
            trigger: 'change',
          },
        ],
        resName: [
          {
            required: true,
            message: '请输入资源名称',
            trigger: 'change',
          },
        ],
        resVersion: [
          {
            required: true,
            message: '请输入资源版本',
            trigger: 'change',
          },
        ],
      };
      if (this.form.parentSequence) {
        return rules_1;
      }
      return rules_2;
    },
  },
  methods: {
    show() {
      this.visible = true;
      this.resourcesSource();
      this.parentResource();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    /**
     * @description: 资源来源获取
     * @param {*}
     * @return {*}
     **/
    resourcesSource() {
      Api.resourcesRegionGet().then((res) => {
        this.regionData = res.result;
      });
    },
    /**
     * @description: 选择资源来源带出资源地址
     * @param {*}
     * @return {*}
     **/
    selectRegion(value) {
      if (!value) return (this.form.basePath = '');
      const regionData = this.regionData;
      for (let item = 0; item < regionData.length; item++) {
        const currentData = regionData[item];
        if (currentData['sourceCode'] === value) {
          this.form.basePath = `/${currentData['sourceCode']}`;
          return '';
        }
      }
      this.form.basePath = '';
    },
    /**
     * @description: 父级资源获取
     * @param {*}
     * @return {*}
     **/
    parentResource() {
      const params = { level: 1, orderField: 'REC_DATE', order: 'desc' };
      Api.parentSequenceGet(params).then((res) => {
        this.parentResources = res.result;
      });
    },
    /**
     * @description: 父级资源选择带出资源版本
     * @param {*}
     * @return {*}
     **/
    handleChange(option) {
      this.versionDisabled = !!option;
      const parentResources = this.parentResources;
      for (let index = 0; index < parentResources.length; index++) {
        const currentResources = parentResources[index];
        if (currentResources['sequenceNbr'] === option) {
          this.form.resVersion = currentResources['resVersion'];
          return '';
        }
      }
    },
    /**
     * @description: 表单提交
     * @param {*}
     * @return {*}
     **/
    onSubmit() {
      (this.$refs.ruleForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          this.loading = true;
          Api.sysResourcesSubmit(this.form)
            .then((res) => {
              if (res) {
                this.$emit('callback');
                this.close();
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    /**
     * @description: 表单关闭
     * @param {*}
     * @return {*}
     **/
    close() {
      this.visible = false;
      (this.$refs.ruleForm as HTMLFormElement).resetFields();
      this.resetForm();
    },
    resetForm() {
      this.form = {
        resName: '',
        serviceCode: undefined,
        basePath: '',
        parentSequence: undefined,
        resVersion: '',
        requestMapping: '',
        requestMethod: undefined,
        resDescription: '',
      };
    },
  },
});
